import { useCallback, type FC } from "react";
// import User01Icon from "@untitled-ui/icons-react/build/esm/User01";
// import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
// import SvgIcon from "@mui/material/SvgIcon";

import { useMockedUser } from "src/hooks/use-mocked-user";
import { usePopover } from "src/hooks/use-popover";

// import { AccountPopover } from "./account-popover";
import Button from "@mui/material/Button";
import { Issuer } from "src/utils/auth";
import { paths } from "src/paths";
import toast from "react-hot-toast";
import { useRouter } from "src/hooks/use-router";
import { useAuth } from "src/hooks/use-auth";

// interface AccountPopoverProps {
//     anchorEl: null | Element;
//     onClose?: () => void;
//     open?: boolean;
// }

export const AccountButton: FC = () => {
    const user = useMockedUser();
    const popover = usePopover<HTMLButtonElement>();

    const onClose = popover.handleClose;
    const router = useRouter();
    const auth = useAuth();

    const handleLogout = useCallback(async (): Promise<void> => {
        try {
            onClose?.();

            switch (auth.issuer) {
                case Issuer.Amplify: {
                    await auth.signOut();
                    break;
                }

                case Issuer.Auth0: {
                    await auth.logout();
                    break;
                }

                case Issuer.Firebase: {
                    await auth.signOut();
                    break;
                }

                case Issuer.JWT: {
                    await auth.signOut();
                    break;
                }

                default: {
                    console.warn("Using an unknown Auth Issuer, did not log out");
                }
            }

            router.push(paths.index);
        } catch (err) {
            console.error(err);
            toast.error("Something went wrong!");
        }
    }, [auth, router, onClose]);

    return (
        <>
            <Box
                component={ButtonBase}
                // onClick={popover.handleOpen}
                ref={popover.anchorRef}
                sx={{
                    alignItems: "center",
                    display: "flex",
                    height: 40,
                }}
            >
                {user.name} 님 환영합니다.
                <Button
                    color="error"
                    onClick={handleLogout}
                    size="small"
                >
                    로그아웃
                </Button>
            </Box>
            {/* <Box
                component={ButtonBase}
                onClick={popover.handleOpen}
                ref={popover.anchorRef}
                sx={{
                    alignItems: "center",
                    display: "flex",
                    borderWidth: 2,
                    borderStyle: "solid",
                    borderColor: "divider",
                    height: 40,
                    width: 40,
                    borderRadius: "50%",
                }}
            >
                <Avatar
                    sx={{
                        height: 32,
                        width: 32,
                    }}
                    src={user.avatar}
                >
                    <SvgIcon>
                        <User01Icon />
                    </SvgIcon>
                </Avatar>
                {user.name} 님 환영합니다.
            </Box> */}
            {/* <AccountPopover
                anchorEl={popover.anchorRef.current}
                onClose={popover.handleClose}
                open={popover.open}
            /> */}
        </>
    );
};
