import ReactDOM from "react-dom/client";
import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { getServerPath, init } from "@seesawinc/calvin_chat_sdk";
import { App } from "src/app";
init(process.env.NODE_ENV);
console.log("init", getServerPath());
const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
console.log(process.env);
console.log("serverPath:", getServerPath());
root.render(
    <HelmetProvider>
        <BrowserRouter>
            <Suspense>
                <App />
            </Suspense>
        </BrowserRouter>
    </HelmetProvider>
);
