import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { Contact, Message, Thread } from 'src/types/chat';
import { objFromArray } from 'src/utils/obj-from-array';

interface ChatState {
  post_list: any[];
}

type GetContactsAction = PayloadAction<Contact[]>;

type GetThreadsAction = PayloadAction<Thread[]>;

type GetThreadAction = PayloadAction<Thread | null>;

type MarkThreadAsSeenAction = PayloadAction<string>;

type SetCurrentThreadAction = PayloadAction<string | undefined>;

type AddMessageAction = PayloadAction<{ message: Message, threadId: string }>;

const initialState: ChatState = {
  post_list: [],

};

const reducers = {
  setPosts (state: ChatState, action: PayloadAction<any[]>) {
    state.post_list = action.payload;

  }

};

export const slice = createSlice({
  name: 'chat',
  initialState,
  reducers
});

export const { reducer } = slice;
