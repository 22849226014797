import type { User } from "src/types/user";
import { createResourceId } from "src/utils/create-resource-id";
import { decode, JWT_EXPIRES_IN, JWT_SECRET, sign } from "src/utils/jwt";
import { wait } from "src/utils/wait";
import { users } from "./data";
import { signIn as signInApi } from "@seesawinc/calvin_chat_sdk/service/auth";
import {getMyUser} from "@seesawinc/calvin_chat_sdk/service/user";

const STORAGE_KEY: string = "users";

// NOTE: We use sessionStorage since memory storage is lost after page reload.
//  This should be replaced with a server call that returns DB persisted data.

const getPersistedUsers = (): User[] => {
    try {
        const data = sessionStorage.getItem(STORAGE_KEY);

        if (!data) {
            return [];
        }

        return JSON.parse(data) as User[];
    } catch (err) {
        console.error(err);
        return [];
    }
};

const persistUser = (user: User): void => {
    try {
        const users = getPersistedUsers();
        const data = JSON.stringify([...users, user]);
        sessionStorage.setItem(STORAGE_KEY, data);
    } catch (err) {
        console.error(err);
    }
};

type SignInRequest = {
    identifier: string;
    password: string;
    signin_method: string;
};

type SignInResponse = Promise<{
    accessToken: string;
    refreshToken: string;
}>;

type SignUpRequest = {
    email: string;
    name: string;
    password: string;
};

type SignUpResponse = Promise<{
    accessToken?: string;
    refreshToken?: string;
}>;

type MeRequest = {
    accessToken: string;
    refreshToken?: string;
};

type MeResponse = Promise<User>;

class AuthApi {
    async signIn(request: SignInRequest): SignInResponse {
        const { identifier, password, signin_method } = request;
        // const result = await fetch(`http://3.34.184.91:8080/auth/signin`, {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json",
        //         "Origin": "http://localhost:3000",
        //         },
        //     body: JSON.stringify({
        //         identifier: identifier,
        //         password: password,
        //         signin_method: signin_method,
        //         }),
        //     });
        // console.log("signin result: ", result);
        // return {
        //     accessToken: "",
        //     refreshToken: "",
        // };
        //
        const response = await signInApi({
            identifier,
            password,
            signin_method,
        });
        console.log("response", response)
        if (response.code===401){
            throw new Error("로그인에 실패하였습니다. 아이디와 비밀번호를 확인해 주세요.");
        }
        return {
            accessToken: response.access_token,
            refreshToken: response.refresh_token,

        }

    }

    async signUp(request: SignUpRequest): SignUpResponse {
        const { email, name, password } = request;

        await wait(1000);

        return new Promise((resolve, reject) => {
            try {
                // Merge static users (data file) with persisted users (browser storage)
                const mergedUsers = [...users, ...getPersistedUsers()];

                // Check if a user already exists
                let user = mergedUsers.find((user) => user.email === email);

                if (user) {
                    reject(new Error("User already exists"));
                    return;
                }

                user = {
                    id: createResourceId(),
                    avatar: undefined,
                    email,
                    name,
                    password,
                    plan: "Standard",
                };

                persistUser(user);
                //
                // const accessToken = sign({ userId: user.id }, JWT_SECRET, {
                //     expiresIn: JWT_EXPIRES_IN,
                // });

                resolve({  });
            } catch (err) {
                console.error("[Auth Api]: ", err);
                reject(new Error("Internal server error"));
            }
        });
    }

    me(request: MeRequest): MeResponse {
        const { accessToken,refreshToken } = request;
        return getMyUser({
            token:accessToken,
            refresh_token:refreshToken,
            request_params:{},
            path_params:{},
            data:{},
            setRefreshToken:()=>{}
        });
        return new Promise((resolve, reject) => {
            try {
                // Decode access token
                const decodedToken = decode(accessToken) as any;

                // Merge static users (data file) with persisted users (browser storage)
                const mergedUsers = [...users, ...getPersistedUsers()];

                // Find the user
                const { userId } = decodedToken;
                const user = mergedUsers.find((user) => user.id === userId);

                if (!user) {
                    reject(new Error("Invalid authorization token"));
                    return;
                }

                resolve({
                    id: user.id,
                    avatar: user.avatar,
                    email: user.email,
                    name: user.name,
                    plan: user.plan,
                });
            } catch (err) {
                console.error("[Auth Api]: ", err);
                reject(new Error("Internal server error"));
            }
        });
    }
}

export const authApi = new AuthApi();

