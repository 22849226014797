import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { ko } from "./translations/ko";
import { en } from "./translations/en";
import { de } from "./translations/de";
import { es } from "./translations/es";

i18n.use(initReactI18next).init({
    resources: {
        ko: { translation: ko },
        en: { translation: en },
        de: { translation: de },
        es: { translation: es },
    },
    // lng: 'en',
    // fallbackLng: 'en',
    lng: "ko",
    fallbackLng: "ko",
    interpolation: {
        escapeValue: false,
    },
});
