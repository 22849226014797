import * as Yup from "yup";
import { useFormik } from "formik";
// import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormHelperText from "@mui/material/FormHelperText";
// import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
// import Typography from "@mui/material/Typography";

// import { RouterLink } from "src/components/router-link";
import { Seo } from "src/components/seo";
import type { AuthContextType } from "src/contexts/auth/jwt-context";
import { useAuth } from "src/hooks/use-auth";
import { useMounted } from "src/hooks/use-mounted";
import { usePageView } from "src/hooks/use-page-view";
import { useRouter } from "src/hooks/use-router";
import { useSearchParams } from "src/hooks/use-search-params";
import { paths } from "src/paths";
// import { AuthIssuer } from "src/sections/auth/auth-issuer";

interface Values {
    identifier: string;
    password: string;
    submit: null;
}

const initialValues: Values = {
    identifier: "calvin123",
    password: "Password123!",
    submit: null,
};

const validationSchema = Yup.object({
    identifier: Yup.string()
        .max(255)
        .required("identifier is required"),
    password: Yup.string().max(255).required("Password is required"),
});

export const Page = () => {
    const isMounted = useMounted();
    const router = useRouter();
    const searchParams = useSearchParams();
    const returnTo = searchParams.get("returnTo");
    // const { issuer, signIn } = useAuth<AuthContextType>();
    const { signIn } = useAuth<AuthContextType>();
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, helpers): Promise<void> => {
            try {
                const response = await signIn(values.identifier, values.password, "USERNAME");

                if (isMounted()) {
                    router.push(returnTo || paths.dashboard.index);
                }
            } catch (err) {
                console.error(err);

                if (isMounted()) {
                    helpers.setStatus({ success: false });
                    helpers.setErrors({ submit: err.message });
                    helpers.setSubmitting(false);
                }
            }
        },
    });

    usePageView();

    return (
        <>
            <Seo title="Login" />
            <div>
                <Card elevation={16}>
                    <CardHeader
                        // subheader={
                        //     <Typography
                        //         color="text.secondary"
                        //         variant="body2"
                        //     >
                        //         Don&apos;t have an account? &nbsp;
                        //         <Link
                        //             component={RouterLink}
                        //             href={paths.auth.jwt.register}
                        //             underline="hover"
                        //             variant="subtitle2"
                        //         >
                        //             Register
                        //         </Link>
                        //     </Typography>
                        // }
                        sx={{ pb: 0 }}
                        title="로그인"
                    />
                    <CardContent>
                        <form noValidate onSubmit={formik.handleSubmit}>
                            <Stack spacing={3}>
                                <TextField
                                    autoFocus
                                    error={
                                        !!(
                                            formik.touched.identifier &&
                                            formik.errors.identifier
                                        )
                                    }
                                    fullWidth
                                    helperText={
                                        formik.touched.identifier &&
                                        formik.errors.identifier
                                    }
                                    label="아이디"
                                    name="identifier"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    type="text"
                                    value={formik.values.identifier}
                                />
                                <TextField
                                    error={
                                        !!(
                                            formik.touched.password &&
                                            formik.errors.password
                                        )
                                    }
                                    fullWidth
                                    helperText={
                                        formik.touched.password &&
                                        formik.errors.password
                                    }
                                    label="비밀번호"
                                    name="password"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    type="password"
                                    value={formik.values.password}
                                />
                            </Stack>
                            {formik.errors.submit && (
                                <FormHelperText error sx={{ mt: 3 }}>
                                    {formik.errors.submit as string}
                                </FormHelperText>
                            )}
                            <Button
                                disabled={formik.isSubmitting}
                                fullWidth
                                size="large"
                                sx={{ mt: 2 }}
                                type="submit"
                                variant="contained"
                            >
                                로그인
                            </Button>
                        </form>
                    </CardContent>
                </Card>
                {/* <Stack
                    spacing={3}
                    sx={{ mt: 3 }}
                >
                    <Alert severity="error">
                        <div>
                            You can use <b>demo@devias.io</b> and password <b>Password123!</b>
                        </div>
                    </Alert>
                    <AuthIssuer issuer={issuer} />
                </Stack> */}
            </div>
        </>
    );
};

export default Page;
