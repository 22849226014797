const getCurrentUserID = () => {
    const token = window.sessionStorage.getItem("accessToken");
    if (token === null || token === undefined) {
        return;
    }
    const parsed = token.split(".");
    const payload = JSON.parse(atob(parsed[1]));
    const user_id = payload.jti;
    return user_id;
}

const getUserRole = () => {
    const token = window.sessionStorage.getItem("accessToken");
    if (token === null || token === undefined) {
        return;
    }
    const parsed = token.split(".");
    const payload = JSON.parse(atob(parsed[1]));
    const role = payload.user_type;
    return role;
}
const isUserManagable = () => {
    const role = getUserRole();
    if (role === "ADMIN" || role === "STAFF") {
        return true;
    }
    return false;
}
export { getCurrentUserID , getUserRole, isUserManagable}