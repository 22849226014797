import type { User } from "src/types/user";
import {getMyUser, getUserByID} from "@seesawinc/calvin_chat_sdk/service/user";
import {useEffect, useState} from "react";


export const useMockedUser = (): User => {
    // To get the user from the authContext, you can use
    // `const { user } = useAuth();`

    const [user, setUser] = useState<User>(
        {
            id: "5e86809283e28b96d2d38537",
            avatar: "/assets/avatars/avatar-anika-visser.png",
            name: "홍길",
            desc: "교직원",
            email: "anika.visser@devias.io",
            description: "컴퓨터공학과 교수입니다.",
            phone: "010-1234-5678",
        }
    );

    const getMe = async () => {

        const token =  window.sessionStorage.getItem("accessToken");
        const refreshToken =  window.sessionStorage.getItem("refreshToken");
        if (!token) {
            throw new Error("No token found");
        }

        if (!token) {
            throw new Error("No token found");
        }

        const response = await getMyUser({
            token,
            refresh_token: refreshToken!,
            path_params: {
            },

            setRefreshToken: async () => {},
        });
        console.log("my response", response);
        setUser({
            id: response.data.id,
            avatar: response.data.profile_image_url,
            name: response.data.name,
            desc: response.data.bio,
            email: response.data.email,
            description: response.data.description,
            phone: response.data.phone_number,
        });
    }
    useEffect(() => {
        getMe();

    }, []);


    return user;
};
